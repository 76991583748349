import Content from "./Content.js";
import LinkedIn from "./svg/LinkedIn.js";
import React from "react";
import Section from "./Section.js";
import I18n from "./I18n.js";

const ExecutiveBoardMembers = [
    {
        img: "static/img/team/team_0006_Denis-Gillet.jpg",
        name: " Denis Gillet",
        role: "Team_EB_President",
        linkedIn: "https://www.linkedin.com/in/denisgillet"
    },
    {
        img: "static/img/team/team_0003_pierre-dillenbourg.jpg",
        name: " Pierre Dillenbourg",
        role: "Team_EB_VicePresident",
        linkedIn: "https://www.linkedin.com/in/pierre-dillenbourg-060171"
    },
    {
        img: "static/img/team/team_0001_Francesco-Mondada.jpg",
        name: "Francesco Mondada",
        role: "Team_EB_Secretary",
        linkedIn: "https://www.linkedin.com/in/francesco-mondada-5b3611"
    },
    {
        img: "static/img/team/team_0004_Patrick-Jermann.png",
        name: "Patrick Jermann",
        role: "Team_EB_Treasurer",
        linkedIn: "https://www.linkedin.com/in/pjermann/"
    }
];
const TeamMembers = [
    {
        img: "static/img/team/team_0009_Roman-Bruegger.jpg",
        name: "Roman Bruegger",
        role: "Team_ManagingDirector",
        email: "contact@edtech-collider.ch",
        linkedIn: "https://www.linkedin.com/in/brueggerroman/"
    },
    {
        img: "static/img/team/Sophia_Reyes_Mury_Team.png",
        name: "Sophia Reyes Mury",
        role: "Team_TestbedManager",
        email: "sophia.reyesmury@edtech-collider.ch",
        linkedIn: "https://ch.linkedin.com/in/sophia-reyes-mury/en"
    },
    {
        img: "static/img/team/Tabea_Widmer.png",
        name: "Tabea Widmer",
        role: "Team_TestbedManager",
        email: "tabea.widmer@edtech-collider.ch",
        linkedIn: "https://www.linkedin.com/in/tabea-widmer-42234770/"
    }
];
const AdvisoryCommitteeMembers = [
    {
        img: "static/img/team/Polka_Hazel_Advisory_Committee.jpg",
        name: "Hazel Polka",
        role: "Team_ACM",
        linkedIn: "https://www.linkedin.com/in/hazelpolka/"
    },
    {
        img: "static/img/team/Sader_Ala_Advisory_Committee_2.jpg",
        name: "Ala Sader",
        role: "Team_ACM",
        linkedIn: "https://www.linkedin.com/in/alasader/"
    },
    {
        img: "static/img/team/Burkhalter_Michael_Advisory_Committee.jpg",
        name: "Michael Burkhalter",
        role: "Team_ACM",
        linkedIn: "https://www.linkedin.com/in/michael-burkhalter-40a127115/"
    },
    {
        img: "static/img/team/Tassetto_Jean-Marc_Advisory_Committee_Member.jpg",
        name: "Jean-Marc Tassetto",
        role: "Team_ACM",
        linkedIn: "https://www.linkedin.com/in/jean-marc-tassetto-39902685/"
    },
    {
        img: "/static/img/team/Rihak_Jan_Advisory_Committee_Member.jpg",
        name: "Jan Rihak",
        role: "Team_ACM",
        linkedIn: "https://www.linkedin.com/in/jan-rihak-4198b12/"
    },
    {
        img: "/static/img/team/Adrian_Hilti_Advisory_Committee.png",
        name: "Adrian Hilti",
        role: "Team_ACM",
        linkedIn: "https://www.linkedin.com/in/ahilti/"
    }
];

const Member = props => {
    return (
        <li className={props.liClass}>
            <img
                alt="App 1"
                className="ae-3 fromCenter margin-bottom-3 margin-top-5"
                height="100"
                src={props.img}
            />
            <div className="fix-45 ae-4">
                <p className="small">{props.name}</p>
                <p className="specs-77">
                    <I18n label={props.role} />
                </p>
                <br />
                {props.linkedIn && (
                    <a
                        target="_blank"
                        className="button small stroke round uppercase text-linkedin"
                        href={props.linkedIn}
                    >
                        <LinkedIn /> LinkedIn
                    </a>
                )}
                {props.email && (
                    <div>
                        <a
                            className="template-text-8 highlighted"
                            href={`mailto:${props.email}`}
                        >
                            {props.email}
                        </a>
                    </div>
                )}
            </div>
        </li>
    );
};

const TeamSection = props => {
    const { members, title } = props;
    const liClass = "col-3-12";
    return (
        <div style={{ paddingTop: "24px", paddingBottom: "24px" }}>
            <div className="fix-12-12">
                <h1 className="ae-1 fromCenter header-11 light">{title}</h1>
                <ul className="grid later">
                    {members.map(member => {
                        return (
                            <Member
                                key={member.name}
                                {...member}
                                liClass={liClass}
                            />
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

const Team = props => {
    return (
        <Section
            scrollId="team"
            sectionProps={{
                className: "slide whiteSlide",
                "data-name": "team"
            }}
        >
            <Content>
                <div className="container">
                    <div className="wrap">
                        <TeamSection
                            members={ExecutiveBoardMembers}
                            title={<I18n label="TeamTitle_EB" />}
                        />
                        <TeamSection
                            members={TeamMembers}
                            title={<I18n label="TeamTitle" />}
                        />
                        <TeamSection
                            members={AdvisoryCommitteeMembers}
                            title={<I18n label="TeamTitle_AC" />}
                        />
                    </div>
                </div>
            </Content>
        </Section>
    );
};

export default Team;
